<template lang="pug">
include ../pug/svg
section#course(v-if="course")
	nav.navbar.sticky
		div
			ul
				li(v-if="hasFeature('course','content')")
					router-link(:to="{name:'ManageLearningCourseContent'}") Content
				li(v-if="hasFeature('course','access')")
					router-link(:to="{name:'ManageLearningCourseAccess'}") Access
				li(v-if="hasFeature('course','settings')")
					router-link(:to="{name:'ManageLearningCourseSettings'}") Settings
				li(v-if="hasFeature('course','activity')")
					router-link(:to="{name:'ManageLearningCourseActivity'}") Activity
				//-li
					router-link(:to="{name:'ManageLearningCourseFeedback'}") Feedback
			button.but.pri.spinner(v-if="userCourseRole.seniority<=40" :class="{spin:saving}" type="button" :disabled="saving||!canSave" @click="saveCourse") Save
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			button.but.pri.spinner(v-if="userCourseRole.seniority<=40" :class="{spin:resetting}" type="button" :disabled="resetting||!canSave" @click="resetCourse") Cancel
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
			//-button.but.pri(type="button" :disabled="canSave||saving||resetting||!defaultLessonId" @click="previewCourse") Preview
			button.but.pri(type="button" :disabled="false" @click="previewCourse") Preview
	ModalConfirm(:active="leaveTo" :useDrag="true" @cancel="leaveTo=null" @confirm="loadCourse")
	router-view(v-slot="{Component}")
		transition(name="section" mode="out-in" enter-active-class="hide" leave-active-class="hide" appear)
			component(:is="Component" :course="course" :cancelled="cancelled" @storeUpdated="storeUpdated($event)")
</template>

<script>
import CourseService from '../services/CourseService';
import ModalConfirm from '../components/ModalConfirm';
export default {
	name: 'ManageLearningCourse',
	components: {
		ModalConfirm,
	},
	data() {
		return {
			courseId: parseInt(this.$route.params.courseId),
			canSave: false,
			canCancel: false,
			canPreview: true,
			cancelled: false,
			resetting: false,
			saving: false,
			leaveTo: null,
		}
	},
	mounted() {
		this.loadCourse();
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		course() {
			return this.$store.getters['courses/getCourse'](this.courseId);
		},
		userCourseRole() {
			return this.$store.getters['courses/getUserCourseRole'](this.courseId);
		},
//		defaultLessonId() {
			//return (this.course.courseLessons.length) ? this.course.courseLessons[0].id : null;
//		},			
		defaultLessonId() {
			// return course entry point
			const lesson = this.course.courseLessons[0];
			let activities = [...this.course.courseUserActivities].filter(a => a.userId === this.user.id);
			let lessonId = null;
			
			if (lesson) {
				lessonId = lesson.id;
				
				if (activities.length) {
					// sort by id desc (most recent first)
					activities.sort((a,b) => b.id - a.id);
					
					const lastActivity = activities[0];
					
					if (lastActivity.activityType !== 'course' && lastActivity.activitySubtype !== 'completed') {
						// get latest stated lesson
						lessonId = activities.find(a => a.activityType === 'lesson' && a.activitySubtype === 'started').lessonId;
					}
				}
			}
			
			return lessonId;
		},
	},
	beforeRouteUpdate(to, from, next) {
		return next();
	},
	beforeRouteLeave (to, from, next) {
		// unsaved changes
		if (this.canSave && !this.leaveTo) {
			this.leaveTo = to;
			this.$store.dispatch('gui/setTintState', true);
			next(false);
			
		} else {
			next();			
		}
	},
	methods: {
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		storeUpdated({valid}) {
			if (!this.cancelled) {
				// changes made in child component, so enable/disable save button based of validity of change
				this.canSave = valid;
				this.canCancel = true;				
			}
		},		
		async saveCourse() {
			try {
				this.saving = true;
				const delay = this.$store.getters['gui/delay'];
				
				// save to db and refresh store
				const {data} = await CourseService.updateCourse(this.course);
				
				this.loadCourse();
				
				await delay;
				this.saving = false;
				
				if (this.$route.name === 'ManageLearningCourseLesson' && this.$route.params.lessonId < 0) {
					// update route with newly assigned id to prevent blank screen
					const {newId} = data.assignedIds.find(a => a.type === 'lesson' && parseInt(a.oldId) === parseInt(this.$route.params.lessonId));
					this.$router.push({
						name:'ManageLearningCourseLesson',
						params:{
							courseId: this.$route.params.courseId,
							lessonId: newId,
						},
					});
				}
				
			} catch (error) {
				console.log('thrown from courseCtrl:', error);
			}
//			this.$store.dispatch('courses/saveCourse', this.course);
		},
		async resetCourse() {
				this.resetting = true;
				const delay = this.$store.getters['gui/delay'];
				
				this.loadCourse();
				
				await delay;
				
				this.resetting = false;
		},
		async loadCourse() {
			// load courses from db
			await this.$store.dispatch('courses/dbCourses');
			
			// disable save until change
			this.canSave = false;
			this.canCancel = false;
		},
		previewCourse() {
			this.$router.push({name:'CourseProgress', params:{courseId:this.courseId,lessonId:this.defaultLessonId}});
		},
	},
}
</script>

<style lang="scss">
	#dialog {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000, .2);
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			padding: 20px;
			background: #CCC;
		}
	}
</style>
